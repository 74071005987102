import React, { useState, useEffect } from "react";
import { useNavigate, useMatch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaBolt, FaCreativeCommonsSampling } from "react-icons/fa";

import "./index.css";

import config from "../../utils/global";
import { error } from "../../utils/toast";
import { noTokenPost, get } from "../../utils/http";
import { useGlobalState } from "../../store/index";

const TopNavigationBar = ({ back, check, onBack }) => {
  const navigate = useNavigate();

  const [globalUserId, setGlobalUserId] = useGlobalState(
    "globalUserId"
  );
  const [globalIsLogin, setGlobalIsLogin] = useGlobalState(
    "globalIsLogin"
  );
  const [globalEnergy, setGlobalEnergy] = useGlobalState(
    "globalEnergy"
  );
  const [globalPoints, setGlobalPoints] = useGlobalState(
    "globalPoints"
  );
  const match = useMatch('*');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuItems] = useState([
    { id: 1, title: 'Danh sách trò chơi', link: '/gameList' },
    { id: 2, title: 'Xổ số', link: '/lottery' },
    { id: 3, title: 'Giúp bạn bè', link: '/friendsHelp' },
    { id: 4, title: 'Nhận thưởng', link: '/prizeClaim' },
    { id: 5, title: 'Tài khoản của tôi', link: '/my1GameAccount' },
    { id: 6, title: 'Yvote', link: config.api.voteURL }
  ]);
  const [topnavClass, setTopnavClass] = useState("topnav-menus-dialog");
  const searchParams = new URLSearchParams(window.location.search);
  const nft_token = searchParams.get("nft_token");

  useEffect(() => {
    fetchNewLogin();
  }, []);

  const fetchNewLogin = async () => {
    if (nft_token) {
      // console.log('fetchNewLogin=>', nft_token);
      await fetchNftLogin(nft_token);
    } else {
      if (check === true) {
        // console.log('check=>', check);
        let localXtoken = window.localStorage.getItem("x-token") || '';
        let localYtoken = window.localStorage.getItem("y-token") || '';
        console.log('localXtoken=>', localXtoken);
        console.log('localYtoken=>', localYtoken);
        if (localXtoken && localYtoken) {
          setGlobalIsLogin(true);
          await fetchUserInfo();
          return;
        }
      } else {

      }
    }
  };

  useEffect(() => {
    const body = document.body;
    if (isMenuOpen === true) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
    return () => {
      body.style.overflow = "auto";
    };
  }, [isMenuOpen]);

  const fetchNftLogin = async (_token) => {
    const rep = await noTokenPost("Login/gameBoxLogin", {
      nft_token: _token,
    });
    if (rep.code === 0) {
      window.localStorage.setItem("x-token", rep.data);
      window.localStorage.setItem("y-token", _token);
      setGlobalIsLogin(true);
      await fetchUserInfo();
    }
    else if (rep.code === 2012) {
      window.location.href = `${config.api.loginURL}${window.location.href}`;
    }
    else {
      error(rep.msg);
    }
  };

  const fetchUserInfo = async () => {
    const rep = await get("user/getUserProfileInfo", {});
    if (rep.code === 0) {
      setGlobalEnergy(rep.data.assetData.energy);
      setGlobalPoints(rep.data.assetData.points);
      setGlobalUserId(rep.data.userInfo.id);
    } else {
      error(rep.msg);
    }
  };

  const toggleMenu = () => {
    if (isMenuOpen === true) {
      setTopnavClass("topnav-menus-dialog");
    } else {
      setTopnavClass("topnav-menus-dialog slide-out");
    }
    setIsMenuOpen(!isMenuOpen);
  };

  const handleUrl = (_id, _url, _title) => {
    if (_id === 6) {
      window.location.href = _url;
      return;
    }
    navigate(_url);
  };

  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <header className="pc-navbar">
        <div className="navbar">
          <div className="navbar-logo">
            <a href="/">
              <img src={`${process.env.PUBLIC_URL}/images/one-production-logo.png`} alt="1Game" />
            </a>
          </div>
          <nav className="navbar-menu">
            <ul>
              {menuItems.map((item, index) => {
                return (
                  <li
                    key={`pc_menus_more_${index}`}
                    className={item.link === match.pathname ? 'achk' : 'li'}
                    onClick={() => handleUrl(item.id, item.link)}
                  >
                    {item.title}
                  </li>
                );
              })}
            </ul>
          </nav>
          {globalIsLogin === true ? (
            <ul className="submenu">
              <li key={`pc_menus_more_8`} onClick={() => handleUrl(8, '/my1GameAccount')}><FaBolt />&nbsp;{globalEnergy}</li>
              <li key={`pc_menus_more_9`} onClick={() => handleUrl(9, '/my1GameAccount')}><FaCreativeCommonsSampling />&nbsp;{globalPoints}</li>
            </ul>
          ) : (
            <div className="login">
              Login
            </div>
          )}
        </div>
      </header>
      <header className={'topnav'}>
        <div className="operating">
          {back ? <div className="icon-back" onClick={handleBack}></div> : null}
          <div className="logo">
            <img src={`${process.env.PUBLIC_URL}/images/one-production-logo.png`} alt="1Game" />
          </div>
        </div>
        {globalIsLogin === true ? (
          <ul className="gameinfo">
            <li key={`gameinfo_1`} onClick={() => handleUrl(8, '/my1GameAccount')}><FaBolt />&nbsp;{globalEnergy}</li>
            <li key={`gameinfo_2`} onClick={() => handleUrl(9, '/my1GameAccount')}><FaCreativeCommonsSampling />&nbsp;{globalPoints}</li>
          </ul>
        ) : (
          <div className="login">
            Login
          </div>
        )}
        <div className="navigation" onClick={toggleMenu}>
          <div className="menus-icon"></div>
        </div>
      </header>
      <div className={topnavClass}>
        <div className="menus-head">
          <div className="menus-head-logo">
            <img src={`${process.env.PUBLIC_URL}/images/one-production-logo.png`} alt="1Game" />
          </div>
          <div className="menus-head-close">
            <div className="close-icon" onClick={toggleMenu}></div>
          </div>
        </div>
        <div className="menus-list">
          {menuItems.map((item, index) => {
            return (
              <div key={`menus_${item.id}_${index}`} className={item.link === match.pathname ? 'current' : 'li'} onClick={() => handleUrl(item.id, item.link)}>{item.title}</div>
            );
          })}
        </div>
      </div>
      <ToastContainer className="custom-toast-container" />
    </>
  );
};

export default TopNavigationBar;
