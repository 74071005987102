import React, { useState, useEffect } from "react";
import copy from "clipboard-copy";
import { FaBolt, FaCreativeCommonsSampling } from "react-icons/fa";

import TopNavigationBar from "../../components/TopNavigationBar";
import Seo from "../../components/Seo";
import Face from '../../components/Face';
import ImageLoader from '../../components/ImageLoader';
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";

import "./index.css";

import { success, error } from "../../utils/toast";
import { get } from "../../utils/http";
import { useGlobalState } from "../../store/index";
import { formatWalletAddress } from "../../utils/tools";

const My1GameAccount = () => {
  const [globalIsLogin] = useGlobalState(
    "globalIsLogin"
  );
  const [loaderState, setLoaderState] = useState(true);
  const [userInfo, setUserInfo] = useState({});
  const [walletInfo, setWalletInfo] = useState({});

  useEffect(() => {
    async function fetchData() {
      await fetchUserInfo();
    }
    if (globalIsLogin === true) {
      fetchData();
    }
  }, [globalIsLogin]);

  const fetchUserInfo = async () => {
    const rep = await get("user/getUserProfileInfo", {});
    if (rep.code === 0) {
      setUserInfo(rep.data.userInfo);
      setWalletInfo(rep.data.assetData);
      setLoaderState(false);
    } else {
      error(rep.msg);
    }
  };

  const handleCopy = (_copyText) => {
    copy(_copyText)
      .then(() => {
        success(`Sao chép thành công!`);
      })
      .catch((error) => {
        error("Sao chép thất bại, vui lòng thử lại!");
      });
  };

  return (
    <div className="app">
      <Seo
        title={`1Game - `}
        description={`1Game - `}
        keywords={`1Game,`}
        image={`${window.location.host}/${process.env.PUBLIC_URL}/images/1game.png`}
        url={window.location.href}
      />
      <TopNavigationBar check={true} />
      <div className="my-1game-account">
        <div className="item-rows">
          {userInfo.head_image ? (
            <div className="face">
              <ImageLoader
                src={userInfo.head_image}
                alt={userInfo.nickname}
              />
            </div>
          ) : (
            <div className="face">
              <Face address={userInfo.wallet_address} />
            </div>
          )}
        </div>
        <div className="item-rows">
          {userInfo.nickname || formatWalletAddress(userInfo.wallet_address)}
        </div>
        <div className="item-text">1Game ID của tôi</div>
        <div className="item">
          <div className="value">{userInfo.id}</div>
          <div className="copy-right" onClick={() => handleCopy(userInfo.id)}>
          </div>
        </div>
        <div className="item-text">Thể lực trò chơi</div>
        <div className="item">
          <div className="value"><FaBolt />&nbsp;{walletInfo.energy}</div>
        </div>
        <div className="item-text">Điểm trò chơi</div>
        <div className="item">
          <div className="value"><FaCreativeCommonsSampling />&nbsp;{walletInfo.points}</div>
        </div>
      </div>
      <Footer />
      <Loader isOpen={loaderState} />
    </div>
  );
};

export default My1GameAccount;
