import React from 'react';
import ReactDOM from 'react-dom/client';
import AppRouter from './routers/Router';

import './global.css';

// import VConsole from 'vconsole';
// const vConsole = new VConsole({ theme: 'dark' });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( <
  AppRouter / >
);