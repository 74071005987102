import React, { useState, useEffect } from "react";
import copy from "clipboard-copy";
import { FaBolt, FaCreativeCommonsSampling } from "react-icons/fa";

import TopNavigationBar from "../../components/TopNavigationBar";
import Seo from "../../components/Seo";
import Face from '../../components/Face';
import ImageLoader from '../../components/ImageLoader';
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";

import "./index.css";

import { success, error } from "../../utils/toast";
import { get } from "../../utils/http";
import { useGlobalState } from "../../store/index";
import { formatWalletAddress } from "../../utils/tools";

const FriendsHelp = () => {
  const [globalUserId] = useGlobalState(
    "globalUserId"
  );
  const [globalIsLogin] = useGlobalState(
    "globalIsLogin"
  );
  const [loaderState, setLoaderState] = useState(true);
  const [inviterHistory, setInviterHistory] = useState([]);
  const [shareUrl, setShareUrl] = useState("");

  useEffect(() => {
    async function fetchData() {
      await fetchRecommendedGame(globalUserId);
    }
    if (globalIsLogin === true) {
      fetchData();
    }
  }, [globalIsLogin]);

  const fetchRecommendedGame = async () => {
    const rep = await get("g1Apps/fetchRecommendedGame", {});
    if (rep.code === 0) {
      setShareUrl(`${window.location.host}/gameDetails?gid=${rep.data || 1}&inviter=${globalUserId}`);
      await fetchInviteRecord();

    } else {
      error(rep.msg);
    }
  };

  const fetchInviteRecord = async () => {
    const rep = await get("g1Apps/getInviteRecord", {});
    if (rep.code === 0) {
      setInviterHistory(rep.data.inviteRecords);
      setLoaderState(false);
    } else {
      error(rep.msg);
    }
  };

  const handleCopy = (_copyText) => {
    copy(_copyText)
      .then(() => {
        success(`Sao chép thành công!`);
      })
      .catch((error) => {
        error("Sao chép thất bại, vui lòng thử lại!");
      });
  };

  return (
    <div className="app">
      <Seo
        title={`1Game -`}
        description={`1Game -`}
        keywords={`1Game,`}
        image={`${window.location.host}/${process.env.PUBLIC_URL}/images/1game.png`}
        url={window.location.href}
      />
      <TopNavigationBar check={true} />
      <div className="friends-help">
        <div className="item-alert">
          Gửi liên kết này cho bạn bè để mời họ. Mỗi lần mời thành công 1 bạn tham gia trò chơi, bạn sẽ nhận được <FaBolt />&nbsp;50 thể lực và <FaCreativeCommonsSampling />&nbsp;50 điểm.
        </div>
        <div className="item-share">
          <div className="value" title={shareUrl}>{shareUrl}</div>
          <div className="copy-right" onClick={() => handleCopy(shareUrl)}>
          </div>
        </div>
        {(inviterHistory && inviterHistory.length > 0) && (
          <ul className="inviter">
            <div className="title">
              Mình đã mời thành công
            </div>
            {inviterHistory.map((items, index) => {
              return (
                <li key={`play_li_${index}_${items.id}`}>
                  {items.head_image ? (
                    <div className="face">
                      <ImageLoader
                        src={items.head_image}
                        alt={items.nickname}
                      />
                    </div>
                  ) : (
                    <div className="face">
                      <Face address={items.wallet_address} />
                    </div>
                  )}
                  <div className="nickname">{items.nickname || formatWalletAddress(items.wallet_address)}</div>
                  <div className="energy"><FaBolt />&nbsp;{items.reward_enery}</div>
                  <div className="points"><FaCreativeCommonsSampling />&nbsp;{items.reward_points}</div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <Footer />
      <Loader isOpen={loaderState} />
    </div>
  );
};

export default FriendsHelp;
