import React, { useState, useEffect } from "react";

import TopNavigationBar from "../../components/TopNavigationBar";
import Seo from "../../components/Seo";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";
import ImageLoader from '../../components/ImageLoader';

import "./index.css";

import { error } from "../../utils/toast";
import { get } from "../../utils/http";
import { useGlobalState } from "../../store/index";

const PrizeClaim = () => {
  const [globalIsLogin] = useGlobalState(
    "globalIsLogin"
  );
  const [loaderState, setLoaderState] = useState(true);
  const [tabList] = useState([
    { id: 0, text: "Chưa nhận thưởng", val: 'unclaimed' },
    { id: 1, text: "Đã nhận thưởng", val: 'redeemed' }
  ]);
  const [tabChecked, setTabChecked] = useState('unclaimed');
  const [unclaimedList, setUnclaimedList] = useState([]);
  const [redeemedList, setRedeemedList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await fetchLotteryRecord(0);
    }
    if (globalIsLogin === true) {
      fetchData();
    }
  }, [globalIsLogin]);

  const fetchLotteryRecord = async (_type) => {
    const rep = await get("g1Apps/getLotteryRecord", {
      pageNo: 1,
      pageSize: 20,
      claimType: _type,
    });
    if (rep.code === 0) {
      if (_type === 1) {
        setRedeemedList(rep.data.lotteryRecords);
      } else {
        setUnclaimedList(rep.data.lotteryRecords);
      }
      setLoaderState(false);
    } else {
      error(rep.msg);
    }
  };

  const handleTag = (chk) => {
    if (chk === 'redeemed') {
      fetchLotteryRecord(1);
    } else {
      fetchLotteryRecord(0);
    }
    setTabChecked(chk);
    setLoaderState(true);
  };

  return (
    <div className="app">
      <Seo
        title={`1Game - `}
        description={`1Game - `}
        keywords={`1Game,`}
        image={`${window.location.host}/${process.env.PUBLIC_URL}/images/1game.png`}
        url={window.location.href}
      />
      <TopNavigationBar check={true} />
      <div className="prize-claim">
        <div className="tags-bar">
          <div className="tags-list">
            {tabList.map((tag, index) => (
              <div
                key={`tag_${index}`}
                className={tabChecked === tag.val ? "chk" : "tag"}
                onClick={() => handleTag(tag.val)}
              >
                {tag.text}
              </div>
            ))}
          </div>
        </div>
        {tabChecked === 'redeemed' ? (
          <>
            {redeemedList.length > 0 ? (
              <ul className="list">
                {redeemedList.map((item, index) => (
                  <li key={`li_redeemed${index}_${item.id}`}>
                    <div className="cover">
                      <ImageLoader
                        src={item.lottery_cover}
                        alt={item.lottery_name_vn}
                      />
                    </div>
                    <div className="lotterys">
                      <div className="title">
                        {item.lottery_name_vn}&nbsp;x&nbsp;{(item.claimed_amount) * (item.award_amount)}
                      </div>
                      <div className="operation">
                        Đã nhận
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="nodata">
                Chưa có dữ liệu!
              </div>
            )}
          </>
        ) : (
          <>
            {unclaimedList.length > 0 ? (
              <ul className="list">
                {unclaimedList.map((item, index) => (
                  <li key={`li_unclaimed${index}_${item.id}`} style={{ cursor: "pointer" }}>
                    <div className="cover">
                      <ImageLoader
                        src={item.lottery_cover}
                        alt={item.lottery_name_vn}
                      />
                    </div>
                    <div className="lotterys">
                      <div className="title">
                        {item.lottery_name_vn}&nbsp;x&nbsp;{(item.unclaimed_amount) * (item.award_amount)}
                      </div>
                      <div className="operation">
                        Làm thế nào để nhận?
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="nodata">
                Chưa có dữ liệu!
              </div>
            )}
          </>
        )}
      </div>
      <Footer />
      <Loader isOpen={loaderState} />
    </div >
  );
};

export default PrizeClaim;
