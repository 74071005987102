import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaBolt } from "react-icons/fa";

import TopNavigationBar from "../../components/TopNavigationBar"; // 导入顶部导航栏组件
import Seo from "../../components/Seo";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";
import ImageLoader from '../../components/ImageLoader';

import "./index.css";

import { error } from "../../utils/toast";
import { noTokenGet } from "../../utils/http";
import { useGlobalState } from "../../store/index";

const GameList = () => {
  const navigate = useNavigate();
  const [globalIsLogin] = useGlobalState(
    "globalIsLogin"
  );
  const [loaderState, setLoaderState] = useState(true);
  const [gameList1, setGameList1] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await fetchGameList(1);
    }
    if (globalIsLogin === true) {
      fetchData();
    }
  }, [globalIsLogin]);

  const fetchGameList = async (_type) => {
    const rep = await noTokenGet("g1Apps/getGameList", {
      pageNo: 1,
      pageSize: 20,
      gameType: _type,
    });
    if (rep.code === 0) {
      switch (_type) {
        case 1:
          setGameList1(rep.data.gameList);
          break;
        default:
          setGameList1(rep.data.gameList);
          break;
      }
      setLoaderState(false);

    } else {
      error(rep.msg);
    }
  };

  const handleGameDetails = (_gid) => {
    navigate(`/gameDetails?gid=${_gid}`);
  };

  return (
    <div className="app">
      <Seo
        title={`1Game -`}
        description={`1Game -`}
        keywords={`1Game,`}
        image={`${window.location.host}/${process.env.PUBLIC_URL}/images/1game.png`}
        url={window.location.href}
      />
      <TopNavigationBar check={true} />
      <div className="game-list">
        <div className="games-list">
          {gameList1 ? (
            <div className="li">
              {/* <div className="count">1Game ({gameList1.length})</div> */}
              <div className="cover-list">
                {gameList1.map((items, index) => {
                  return (
                    <div key={`game_img_${index}_${items.id}`} className="game" onClick={() => handleGameDetails(items.id)}>
                      <ImageLoader
                        src={items.game_cover}
                        alt={items.game_name}
                      />
                      <div className="game-energy"><FaBolt />&nbsp;{items.need_energy}</div>
                      <div className="game-name">{items.game_name}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <Footer />
      <Loader isOpen={loaderState} />
    </div>
  );
};

export default GameList;
